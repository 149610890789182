import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddBranch = () => {
  const history = useHistory();
  const params = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");

  const { id } = params;

  const onSubmit = async (data) => {
    setLoader(true);

    const formData = {
      roleId: id,
      permissions: JSON.stringify(data),
    };

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/permission`;
    try {
      let response = await apiCall(url, AuthorizedPostHeaders(formData, token));
      if (response.status === "success") {
        setLoader(false);
        history.push(`/roles/assign-permissions/${id}`);
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  const roleOptions = [
    { value: "create_role", label: "Create Role" },
    { value: "update_role", label: "Update Role" },
    { value: "delete_role", label: "Delete Role" },
  ];

  const permissionOptions = [
    { value: "assign_permission", label: "Assign Permission" },
  ];

  const staffOptions = [
    { value: "create_staff", label: "Create Staff" },
    { value: "update_staff", label: "Update Staff" },
    { value: "delete_staff", label: "Delete Staff" },
  ];

  const attendanceOptions = [
    { value: "mark_attendance", label: "Mark Attendance" },
    { value: "attendance_report", label: "Attendance Report" },
  ];

  const payrollOptions = [
    { value: "generate_payroll", label: "Generate Payroll" },
    { value: "payroll_report", label: "Payroll Report" },
  ];

  const eventOptions = [
    { value: "create_event", label: "Create Event" },
    { value: "update_event", label: "Update Event" },
    { value: "delete_event", label: "Delete Event" },
  ];

  const groupedOptions = [
    {
      label: "Roles",
      options: roleOptions,
    },
    {
      label: "Permissions",
      options: permissionOptions,
    },
    {
      label: "Staff",
      options: staffOptions,
    },
    {
      label: "Attendance",
      options: attendanceOptions,
    },

    {
      label: "Event",
      options: eventOptions,
    },

    // {
    //   label: "Payroll",
    //   options: payrollOptions,
    // },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Roles" breadcrumbItem="Assign Permission" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="assing-accordion" className="custom-accordion">
                  <Card>
                    <CardBody style={{ minHeight: 400 }}>
                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="dashboard"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Dashboard</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    { label: "Activity", value: "activity" },
                                    { label: "Charts", value: "charts" },
                                    { label: "Cases", value: "cases" },
                                  ]}
                                />
                                {errors?.dashboard?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.dashboard?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <Controller
                            name="city"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">City</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create City",
                                      value: "create_city",
                                    },
                                    {
                                      label: "Update City",
                                      value: "update_city",
                                    },
                                    {
                                      label: "Delete City",
                                      value: "delete_city",
                                    },
                                  ]}
                                />
                                {errors?.dashboard?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.dashboard?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="branch"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Branch</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Branch",
                                      value: "create_branch",
                                    },
                                    {
                                      label: "Update Branch",
                                      value: "update_branch",
                                    },
                                    {
                                      label: "Delete Branch",
                                      value: "delete_branch",
                                    },
                                  ]}
                                />
                                {errors?.dashboard?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.dashboard?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <Controller
                            name="hr"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Human Resource</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={groupedOptions}
                                />
                                {errors?.client?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.client?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="caseCategory"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Case Category</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Case Category",
                                      value: "create_case_category",
                                    },
                                    {
                                      label: "Update Case Category",
                                      value: "update_case_category",
                                    },
                                    {
                                      label: "Delete Case Category",
                                      value: "delete_case_category",
                                    },
                                  ]}
                                />
                                {errors?.client?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.client?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md="6">
                          <Controller
                            name="cases"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Cases</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Cases",
                                      value: "create_cases",
                                    },
                                    {
                                      label: "Update Cases",
                                      value: "update_cases",
                                    },
                                    {
                                      label: "Delete Cases",
                                      value: "delete_cases",
                                    },
                                    {
                                      label: "Cause Lists",
                                      value: "cause_lists",
                                    },
                                  ]}
                                />
                                {errors?.cases?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.cases?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="clientCategory"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Client Category</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Client Category",
                                      value: "create_client_category",
                                    },
                                    {
                                      label: "Update Client Category",
                                      value: "update_client_category",
                                    },
                                    {
                                      label: "Delete Client Category",
                                      value: "delete_client_category",
                                    },
                                  ]}
                                />
                                {errors?.client?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.client?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <Controller
                            name="client"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Client</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Client",
                                      value: "create_client",
                                    },
                                    {
                                      label: "Update Client",
                                      value: "update_client",
                                    },
                                    {
                                      label: "Delete Client",
                                      value: "delete_client",
                                    },
                                  ]}
                                />
                                {errors?.client?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.client?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="courtCategory"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Court Category</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Court Category",
                                      value: "create_court_category",
                                    },
                                    {
                                      label: "Update Court Category",
                                      value: "update_court_category",
                                    },
                                    {
                                      label: "Delete Court Category",
                                      value: "delete_court_category",
                                    },
                                  ]}
                                />
                                {errors?.courtCategory?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.courtCategory?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <Controller
                            name="court"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Court</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Court",
                                      value: "create_court",
                                    },
                                    {
                                      label: "Update Court",
                                      value: "update_court",
                                    },
                                    {
                                      label: "Delete Court",
                                      value: "delete_court",
                                    },
                                  ]}
                                />
                                {errors?.court?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.court?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="task"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Tasks</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Task",
                                      value: "create_task",
                                    },
                                    {
                                      label: "Update Task",
                                      value: "update_task",
                                    },
                                    {
                                      label: "Delete Task",
                                      value: "delete_task",
                                    },
                                  ]}
                                />
                                {errors?.task?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.task?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <Controller
                            name="appointments"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Appointments</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Appointments",
                                      value: "create_appointments",
                                    },
                                    {
                                      label: "Update Appointments",
                                      value: "update_appointments",
                                    },
                                    {
                                      label: "Delete Appointments",
                                      value: "delete_appointments",
                                    },
                                  ]}
                                />
                                {errors?.client?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.client?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="expenseTypes"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Expense Types</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Expense Types",
                                      value: "create_expense_types",
                                    },
                                    {
                                      label: "Update Expense Types",
                                      value: "update_expense_types",
                                    },
                                    {
                                      label: "Delete Expense Types",
                                      value: "delete_expense_types",
                                    },
                                  ]}
                                />
                                {errors?.expenseTypes?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.expenseTypes?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <Controller
                            name="expenses"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Expenses</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Expense",
                                      value: "create_expense",
                                    },
                                    {
                                      label: "Update Expense",
                                      value: "update_expense",
                                    },
                                    {
                                      label: "Delete Expense",
                                      value: "delete_expense",
                                    },
                                  ]}
                                />
                                {errors?.expense?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.expense?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="todo"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">To Do</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create To Do",
                                      value: "create_to_do",
                                    },
                                    {
                                      label: "Update To Do",
                                      value: "update_to_do",
                                    },
                                    {
                                      label: "Delete To Do",
                                      value: "delete_to_do",
                                    },
                                  ]}
                                />
                                {errors?.todo?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.todo?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        {/* <Col md="6">
                          <Controller
                            name="notice"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Notice</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Notice",
                                      value: "create_notice",
                                    },
                                    {
                                      label: "Update Notice",
                                      value: "update_notice",
                                    },
                                    {
                                      label: "Delete Notice",
                                      value: "delete_notice",
                                    },
                                  ]}
                                />
                                {errors?.expense?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.expense?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col> */}
                      </Row>

                      {/* <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="leaveType"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Leave Type</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Leave Type",
                                      value: "create_leave_type",
                                    },
                                    {
                                      label: "Update Leave Type",
                                      value: "update_leave_type",
                                    },
                                    {
                                      label: "Delete Leave Type",
                                      value: "delete_leave_type",
                                    },
                                  ]}
                                />
                                {errors?.leave?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.leave?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md="6">
                          <Controller
                            name="leave"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Leaves</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Apply Leave",
                                      value: "apply_leave",
                                    },
                                    {
                                      label: "Approved Leave",
                                      value: "approved_leave",
                                    },
                                    {
                                      label: "Pending Leave",
                                      value: "pending_leave",
                                    },
                                  ]}
                                />
                                {errors?.leave?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.leave?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row> */}

                      {/* <Row style={{ marginBottom: 10 }}>
                        <Col md="6">
                          <Controller
                            name="holiday"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Holiday</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Create Holiday",
                                      value: "create_holiday",
                                    },
                                    {
                                      label: "Update Holiday",
                                      value: "update_holiday",
                                    },
                                    {
                                      label: "Delete Holiday",
                                      value: "delete_holiday",
                                    },
                                  ]}
                                />
                                {errors?.holiday?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.holiday?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/branch")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddBranch;
