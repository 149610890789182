import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";
import AuthorizedPutHeaders from "../../configs/AuthorizedPutHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const EditBranch = ({ history }) => {
  const params = useParams();

  const { id } = params;
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState("initial");
  const [singleData, setSingleData] = useState({});
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });
  const [cities, setCities] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  // Fetching Single Details
  const fetchSingleDetail = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/branch/${id}`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        setSingleData(response?.data);
        if (response?.data?.file) {
          setFile({
            selectedFile: response?.data?.file,
          });
        }

        reset({
          title: response?.data?.title,
        });
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      history.push("/branch");
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchSingleDetail();
  }, [id, reset]);

  // File Method
  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  // Form Submit
  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("title", data?.title);
    if (file?.selectedFile && file?.filePreview) {
      formData.append("file", file?.selectedFile);
    }
    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/branch/${id}`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPutHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/branch");
        toast.success("Successfully updated");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Branches" breadcrumbItem="Edit Branch" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addproduct-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      {Object.keys(singleData?.length === 0) &&
                        state === "loading" && (
                          <center>
                            <Spinner
                              style={{ width: "2rem", height: "2rem" }}
                              children={false}
                            />
                          </center>
                        )}
                      {Object.keys(singleData?.length > 0) &&
                        state === "success" && (
                          <>
                            <Row>
                              <Col lg="12">
                                <div
                                  id="addBranch-accordion"
                                  className="custom-accordion"
                                >
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="title">Title</Label>
                                                <Input
                                                  invalid={errors.title}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.title?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="title"
                                            control={control}
                                            rules={{
                                              required: "Title is required",
                                            }}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="phoneNumber">
                                                  Phone Number
                                                </Label>
                                                <Input
                                                  invalid={errors.phoneNumber}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.phoneNumber?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="phoneNumber"
                                            control={control}
                                            rules={{
                                              required:
                                                "Phone Number is required",
                                            }}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="latitude">
                                                  Latitude
                                                </Label>
                                                <Input
                                                  invalid={errors.latitude}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.latitude?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="latitude"
                                            control={control}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="longitude">
                                                  Longitude
                                                </Label>
                                                <Input
                                                  invalid={errors.longitude}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.longitude?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="longitude"
                                            control={control}
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="postcode">
                                                  Postcode / Zipcode
                                                </Label>
                                                <Input
                                                  invalid={errors.postcode}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.postcode?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="postcode"
                                            control={control}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Controller
                                            name="cityId"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                              required: "City is required",
                                            }}
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="title">
                                                  Select City
                                                </Label>
                                                <SelectComponent
                                                  field={field}
                                                  options={[
                                                    {
                                                      label: "Peshawar",
                                                      value: "Peshawar",
                                                    },
                                                  ]}
                                                />
                                                {errors?.cityId?.message && (
                                                  <FormFeedback className="invalid-tooltip-select mb20">
                                                    {errors?.cityId?.message}
                                                  </FormFeedback>
                                                )}
                                              </FormGroup>
                                            )}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="regNo">
                                                  Registration no
                                                </Label>
                                                <Input
                                                  invalid={errors.regNo}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.regNo?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="regNo"
                                            control={control}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="ntnNo">
                                                  Ntn no
                                                </Label>
                                                <Input
                                                  invalid={errors.ntnNo}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.ntnNo?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="ntnNo"
                                            control={control}
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md="6">
                                          <Label for="icon">Banner Img</Label>
                                          <div className="fileUploader">
                                            <div className="fileContainer">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                onClick={() => {
                                                  document
                                                    .getElementById("getFile")
                                                    .click();
                                                  setFile({
                                                    selectedFile: null,
                                                    filePreview: null,
                                                  });
                                                }}
                                              >
                                                <i className="uil-cloud-upload mr5"></i>{" "}
                                                <small>
                                                  {file?.selectedFile
                                                    ? file?.selectedFile?.name
                                                    : "Upload file here"}
                                                </small>
                                              </div>
                                            </div>
                                          </div>

                                          <input
                                            type="file"
                                            onChange={onFileChange}
                                            id="getFile"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/branch")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditBranch;
