import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddStaff = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });

  const [cities, setCities] = useState([]);

  const [branches, setBranches] = useState([]);

  const [clientCategories, setClientCategories] = useState([]);

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const fetchCities = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCities([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCities(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const fetchBranches = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/branch`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setBranches([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setBranches(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchClientCategories = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/clientCategory`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setClientCategories([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setClientCategories(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchClientCategories();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("phoneNumber", data?.phoneNumber);
    formData.append("gender", data?.gender?.value);
    formData.append("address", data?.clientAddress);
    formData.append("clientCategoryId", data?.clientCategoryId?.value);
    formData.append("cityId", data?.cityId?.value);
    formData.append("branchId", data?.branchId?.value);
    if (file?.selectedFile !== null) {
      formData.append("file", file?.selectedFile);
    }

    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/client`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPostHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/client");
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Client" breadcrumbItem="Add Client" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addStaff-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="name">Name</Label>
                                <Input
                                  invalid={errors.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.name?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="name"
                            control={control}
                            rules={{ required: "Name is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="email">Email</Label>
                                <Input
                                  type="email"
                                  invalid={errors.email}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.email?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="email"
                            rules={{ required: "Email is required" }}
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="phoneNumber">Phone Number</Label>
                                <Input
                                  invalid={errors.phoneNumber}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.phoneNumber?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="phoneNumber"
                            control={control}
                            rules={{ required: "Phone Number is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="gender"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Gender required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Gender</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={[
                                    {
                                      label: "Male",
                                      value: "Male",
                                    },
                                    {
                                      label: "Female",
                                      value: "Female",
                                    },
                                  ]}
                                />
                                {errors?.gender?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.gender?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            name="clientCategoryId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Client Category required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">
                                  Select Client Category
                                </Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={clientCategories}
                                />
                                {errors?.clientCategoryId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.clientCategoryId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="clientAddress">
                                  Client Address
                                </Label>
                                <Input
                                  invalid={errors.clientAddress}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.clientAddress?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="clientAddress"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="cityId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "City is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select City</Label>
                                <SelectComponent
                                  field={field}
                                  options={cities}
                                />
                                {errors?.cityId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.cityId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="branchId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Branch is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Branch</Label>
                                <SelectComponent
                                  field={field}
                                  options={branches}
                                />
                                {errors?.branchId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.branchId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/staff")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddStaff;
