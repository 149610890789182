import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Table,
  Label,
  Input,
} from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";
import AuthorizedDeleteHeader from "../../configs/AuthorizedDeleteHeader";

// Toaster
import toast from "react-hot-toast";
import { LIMIT } from "../../helpers/pagination";

const RoleList = ({ history }) => {
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState("success"); //after calling the API make it to initial

  // Fetching roles
  const dummyData = [
    {
      id: 1,
      name: "Bassam Siddiqui",
      phoneNumber: "0912243333",
      email: "bassam@gmail.com",
      status: "-54.1223333",
      role: "25000",
      city: "Peshawar",
    },
    {
      id: 2,
      name: "Awais rafiq",
      phoneNumber: "0912243333",
      email: "awais@yahoo.com",
      status: "-54.1223333",
      role: "25000",
      city: "Mardan",
    },
    {
      id: 3,
      name: "Sherry",
      phoneNumber: "0912243333",
      email: "shery@outlook.com",
      status: "-54.1223333",
      role: "25000",
    },
  ];
  const [roles, setRoles] = useState(dummyData);

  // Pagination
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const fetchRoles = async () => {
    setLoader(true);
    if (page === 1) {
      setState("loading");
    }
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/attendance?limit=${LIMIT}&page=${page}`;

    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token), history);

      if (response?.data?.rows?.length > 0) {
        setLoader(false);
        setState("success");
        setRoles((roles) => [...roles, ...response?.data?.rows]);
        if (response?.data?.hasOwnProperty("next")) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else {
        setLoader(false);
        setState("success");
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchRoles();
  }, [page]);

  const editRecordHandler = (id) => {
    history.push(`/edit-role/${id}`);
  };

  const deleteRecordHandler = async (id) => {
    if (window.confirm("You want to delete this entry?")) {
      const token = JSON.parse(localStorage.getItem("token"));
      let url = `${apiUrl}/role/${id}`;
      try {
        let response = await apiCall(url, AuthorizedDeleteHeader(token));
        if (response) {
          setRoles([]);
          fetchRoles();
          toast.success("Successfully Deleted");
        }
      } catch (err) {
        setLoader(false);
        toast.error(err);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Roles" breadcrumbItem="Role List" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    {/* <Button
                      onClick={() => history.push("/add-role")}
                      type="button"
                      color="success"
                      className="waves-effect waves-light mb-3"
                    >
                      <i className="mdi mdi-plus me-1"></i>
                      Add New Role
                    </Button> */}
                    <h6>Attendance for : {moment().format("DD-MM-YYYY")}</h6>
                  </div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sno</th>
                            <th className="text-center" data-priority="1">
                              Staff Name
                            </th>

                            {/* {userDetails?.roleId === 1 && ( */}
                            <th className="text-center" data-priority="3">
                              Attendance
                            </th>
                            {/* )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {roles?.length === 0 && state === "loading" && (
                            <tr>
                              <td colSpan="6">
                                <center>
                                  <Spinner
                                    style={{ width: "2rem", height: "2rem" }}
                                    children={false}
                                  />
                                </center>
                              </td>
                            </tr>
                          )}

                          {roles?.length === 0 && state === "success" && (
                            <tr>
                              <td colSpan="6">
                                <center>NO RECORD FOUND....</center>
                              </td>
                            </tr>
                          )}

                          {roles?.length === 0 && state === "error" && (
                            <tr>
                              <td colSpan="6">
                                <center>
                                  Something went wrong please try again....
                                </center>
                              </td>
                            </tr>
                          )}

                          {roles?.length > 0 &&
                            state === "success" &&
                            roles.map((item, index) => (
                              <tr key={`cr-${item.id}`}>
                                <th>{index + 1}</th>

                                <td>{item.name}</td>

                                <td>
                                  <Row>
                                    <Col md="3">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formrow-customCheck"
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="formrow-customCheck"
                                        >
                                          Present
                                        </Label>
                                      </div>
                                    </Col>

                                    <Col md="3">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formrow-customCheck"
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="formrow-customCheck"
                                        >
                                          Late
                                        </Label>
                                      </div>
                                    </Col>

                                    <Col md="3">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formrow-customCheck"
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="formrow-customCheck"
                                        >
                                          Absent
                                        </Label>
                                      </div>
                                    </Col>

                                    <Col md="3">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formrow-customCheck"
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="formrow-customCheck"
                                        >
                                          Holiday
                                        </Label>
                                      </div>
                                    </Col>
                                  </Row>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    {hasMore && (
                      <Col className="col-12">
                        <center>
                          <Button
                            onClick={() => {
                              setPage(page + 1);
                            }}
                            type="button"
                            color="primary"
                            className="waves-effect waves-light mb-3"
                          >
                            {/* <i className="mdi mdi-loading-full me-1"></i> */}
                            {loader ? "loading..." : "Load More"}
                          </Button>
                        </center>
                      </Col>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RoleList;
