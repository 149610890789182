import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
// Form Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddTask = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });

  const [description, setDescriptionState] = useState("");

  const [cities, setCities] = useState([]);

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const fetchCities = async () => {
    // setLoader(true);
    // setState("loading");
    // const token = JSON.parse(localStorage.getItem("token"));
    // let url = `${apiUrl}/common/get/all/divisions`;
    // try {
    //   let response = await apiCall(url, AuthorizedGetHeaders(token));
    //   if (response) {
    //     setLoader(false);
    //     setState("success");
    //     let dataArray = [];
    //     if (response?.data?.divisions?.length > 0) {
    //       setDivisions([]);
    //       response?.data?.divisions.map((item) =>
    //         dataArray.push({ value: item?.id, label: item?.title })
    //       );
    //       setDivisions(dataArray);
    //     }
    //   }
    // } catch (err) {
    //   setLoader(false);
    //   setState("error");
    //   if (err.message) {
    //     toast.error(err.message);
    //   } else {
    //     toast.error(err);
    //   }
    // }
  };
  useEffect(() => {
    fetchCities();
  });

  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("title", data?.title);
    if (file?.selectedFile !== null) {
      formData.append("file", file?.selectedFile);
    }

    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/brand`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPostHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/brand");
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Task" breadcrumbItem="Add Task" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addTask-accordion" className="custom-accordion">
                  <Card>
                    <CardBody style={{ minHeight: 550 }}>
                      <Row>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="taskTitle">Task Title</Label>
                                <Input
                                  invalid={errors.taskTitle}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.taskTitle?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="taskTitle"
                            control={control}
                            rules={{ required: "Task Title is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="startDate">Start Date</Label>
                                <Input
                                  type="date"
                                  invalid={errors.startDate}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.startDate?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="startDate"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="dueDate">Due Date</Label>
                                <Input
                                  type="date"
                                  invalid={errors.dueDate}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.dueDate?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="dueDate"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="priority"
                            defaultValue=""
                            control={control}
                            // rules={{ required: " required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="priority">Priority</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={[
                                    {
                                      label: "Low",
                                      value: "Low",
                                    },
                                    {
                                      label: "Medium",
                                      value: "Medium",
                                    },
                                    {
                                      label: "High",
                                      value: "High",
                                    },

                                    {
                                      label: "Urgent",
                                      value: "Urgent",
                                    },
                                  ]}
                                />
                                {errors?.priority?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.priority?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            name="branchId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Branch required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Branch</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={[
                                    {
                                      label: "Peshawar",
                                      value: "Peshawar",
                                    },
                                  ]}
                                />
                                {errors?.branchId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.branchId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md="3">
                          <Controller
                            name="caseId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Case required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Case</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={[
                                    {
                                      label: "Random Case",
                                      value: "Random Case",
                                    },
                                  ]}
                                />
                                {errors?.caseId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.caseId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="associates"
                            defaultValue=""
                            control={control}
                            rules={{
                              required: "Atleast one associate is required",
                            }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Associates</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={[
                                    {
                                      label: "Bassam Siddquie",
                                      value: "Bassam Siddquie",
                                    },
                                    { label: "Shery", value: "Shery" },
                                  ]}
                                />
                                {errors?.caseId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.caseId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 10 }}>
                        <Col md="12">
                          <Label for="description">Description</Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescriptionState(data);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 10 }}>
                        <Col md="3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="formrow-customCheck"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="formrow-customCheck"
                            >
                              Mark as complete
                            </Label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/staff")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddTask;
