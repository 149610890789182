import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
// Form Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddCase = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });

  const [description, setDescriptionState] = useState("");

  const [cities, setCities] = useState([]);
  const [caseCategories, setCaseCategories] = useState([]);
  const [branches, setBranches] = useState([]);
  const [clients, setClients] = useState([]);
  const [staff, setStaff] = useState([]);
  const [courts, setCourts] = useState([]);
  const [disposeCase, setDisposeCase] = useState(false);

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const fetchCaseCategories = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/caseCategory`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCaseCategories([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCaseCategories(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCaseCategories();
  }, []);

  const fetchCities = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCities([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCities(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const fetchBranches = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/branch`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setBranches([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setBranches(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchCourts = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/court`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));

      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCourts([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.courtName })
          );
          setCourts(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCourts();
  }, []);

  const fetchClients = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/client`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setClients([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.name })
          );
          setClients(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        setLoader(false);
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchClients();
  }, []);

  const fetchStaff = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/staff`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setStaff([]);
          response?.data?.rows.map((item) => {
            if (item?.role?.title) {
              dataArray.push({
                value: item?.id,
                label: `${item?.name} ${
                  item?.role?.title ? " - " + item.role.title : ""
                }`,
              });
            }
          });
          setStaff(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        setLoader(false);
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchStaff();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("caseTitle", data?.caseTitle);
    formData.append("caseNo", data?.caseNo);
    formData.append("caseFileNo", data?.caseFileNo);
    formData.append("caseSection", data?.caseSection);
    formData.append("caseReceivingDate", data?.caseReceivingDate);
    formData.append("caseFilingDate", data?.caseFilingDate);
    formData.append("referenceName", data?.referenceName);
    formData.append("referenceMobileNo", data?.referenceMobileNo);
    formData.append("opponentLawyer", data?.opponentLawyer);
    formData.append("opponentLawyerMobileNo", data?.opponentLawyerMobileNo);
    formData.append("caseFee", data?.caseFee);
    formData.append("description", description);
    formData.append("caseCategoryId", data?.caseCategoryId?.value);
    formData.append("cityId", data?.cityId?.value);
    formData.append("courtId", data?.courtId?.value);
    formData.append("clientId", data?.clientId?.value);
    formData.append("branchId", data?.branchId?.value);
    formData.append("disposeCase", disposeCase);
    formData.append("associates", JSON.stringify(data?.associates));
    if (file?.selectedFile !== null) {
      formData.append("file", file?.selectedFile);
    }

    // Form Data End
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/case`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPostHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/case");
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Case" breadcrumbItem="Add Case" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addCase-accordion" className="custom-accordion">
                  <Card>
                    <CardBody style={{ minHeight: 550 }}>
                      <Row>
                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseTitle">Case Title</Label>
                                <Input
                                  invalid={errors.caseTitle}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseTitle?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseTitle"
                            control={control}
                            rules={{ required: "Case Title is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseNo">Case No</Label>
                                <Input
                                  type="text"
                                  invalid={errors.caseNo}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseNo?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseNo"
                            // rules={{ required: "Case No is required" }}
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseFileNo">Case File no</Label>
                                <Input
                                  invalid={errors.caseFileNo}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseFileNo?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseFileNo"
                            control={control}
                            // rules={{ required: "Phone Number is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseSection">
                                  Case Filed Under Section
                                </Label>
                                <Input
                                  invalid={errors.caseSection}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseSection?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseSection"
                            control={control}
                            // rules={{ required: "Phone Number is required" }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseReceivingDate">
                                  Case Receiving Date
                                </Label>
                                <Input
                                  type="date"
                                  invalid={errors.caseReceivingDate}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseReceivingDate?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseReceivingDate"
                            control={control}
                            // rules={{ required: "Case Title is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseFilingDate">
                                  Case Filing Date
                                </Label>
                                <Input
                                  type="date"
                                  invalid={errors.caseFilingDate}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseFilingDate?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseFilingDate"
                            // rules={{ required: "Case No is required" }}
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="referenceName">
                                  Case Reference Name
                                </Label>
                                <Input
                                  invalid={errors.referenceName}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.referenceName?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="referenceName"
                            control={control}
                            // rules={{ required: "Phone Number is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="referenceMobileNo">
                                  Case Reference Mobile
                                </Label>
                                <Input
                                  invalid={errors.referenceMobileNo}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.referenceMobileNo?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="referenceMobileNo"
                            control={control}
                            // rules={{ required: "Phone Number is required" }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="opponentLawyer">
                                  Case Opponent Lawyer
                                </Label>
                                <Input
                                  type="text"
                                  invalid={errors.opponentLawyer}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.opponentLawyer?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="opponentLawyer"
                            control={control}
                            // rules={{ required: "Case Title is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="opponentLawyerMobileNo">
                                  Case Opponent Mobile No
                                </Label>
                                <Input
                                  type="text"
                                  invalid={errors.opponentLawyerMobileNo}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.opponentLawyerMobileNo?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="opponentLawyerMobileNo"
                            // rules={{ required: "Case No is required" }}
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="caseFee">Case Fee</Label>
                                <Input
                                  invalid={errors.caseFee}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.caseFee?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="caseFee"
                            control={control}
                            // rules={{ required: "Phone Number is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            name="caseCategoryId"
                            control={control}
                            rules={{ required: "Case Category required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Case Category</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={caseCategories}
                                />
                                {errors?.caseCategoryId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.caseCategoryId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            defaultValue=""
                            name="cityId"
                            control={control}
                            rules={{ required: "City is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select City</Label>
                                <SelectComponent
                                  field={field}
                                  options={cities}
                                />
                                {errors?.cityId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.cityId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md="3">
                          <Controller
                            name="branchId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Branch required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Branch</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={branches}
                                />
                                {errors?.branchId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.branchId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="clientId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Client required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Client</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={clients}
                                />
                                {errors?.clientId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.clientId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="courtId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Court is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Court</Label>
                                <SelectComponent
                                  field={field}
                                  options={courts}
                                />
                                {errors?.courtId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.courtId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 10 }}>
                        <Col md="3">
                          <Controller
                            name="associates"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Associate is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Associate</Label>
                                <SelectComponent
                                  isMulti
                                  field={field}
                                  options={staff}
                                />
                                {errors?.associates?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.associates?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 10 }}>
                        <Col md="12">
                          <Label for="description">Description</Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescriptionState(data);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt20">
                        <Col md="3">
                          <div className="form-check">
                            <Input
                              checked={disposeCase}
                              onChange={() => setDisposeCase(!disposeCase)}
                              type="checkbox"
                              className="form-check-input"
                              id="formrow-customCheck"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="formrow-customCheck"
                            >
                              Dispose Case
                            </Label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/staff")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCase;
