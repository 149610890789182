import React from "react";
import Select from "react-select";
const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#2f323f",
    color: "#9699a8 !important",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#343747" : "#343747",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#343747" : "#343747",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
};
export default function SelectComponent({
  options,
  selectedOptions,
  field,
  isMulti,
}) {
  return (
    <Select
      isMulti={isMulti}
      {...field}
      styles={customStyles}
      options={options}
    />
  );
}
