import React, { useState } from "react";
import Switch from "react-switch";

export default function SwitchComponent({ onValue, offValue, status }) {
  const [switch1, setswitch1] = useState(true);
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {offValue}
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {onValue}
      </div>
    );
  };
  return (
    <Switch
      uncheckedIcon={<Offsymbol />}
      checkedIcon={<OnSymbol />}
      onColor="#626ed4"
      onChange={() => {
        setswitch1(!switch1);
      }}
      checked={status === "Active" ? true : false}
    />
  );
}
