import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddStaff = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");
  const [staffStatus, setStaffStatus] = useState(false);
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });
  const [roles, setRoles] = useState([]);
  const [branches, setBranches] = useState([]);

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const fetchRoles = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/role`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setRoles([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setRoles(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchBranches = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/branch`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setBranches([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setBranches(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchBranches();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("phoneNumber", data?.phoneNumber);
    formData.append("password", data?.password);
    formData.append("dob", data?.dob);
    formData.append("currentAddress", data?.currentAddress);
    formData.append("permanentAddress", data?.permanentAddress);
    formData.append("roleId", data?.roleId?.value);
    formData.append("branchId", data?.branchId?.value);
    formData.append("bankName", data?.bankName);
    formData.append("bankBranchName", data?.bankBranchName);
    formData.append("accountName", data?.accountName);
    formData.append("accountNumber", data?.accountNumber);
    formData.append("dateOfJoining", data?.dateOfJoining);
    formData.append("basicSalary", data?.basicSalary);
    formData.append("employmentType", data?.employmentType?.value);
    formData.append("provisionTime", data?.provisionTime);
    formData.append("status", staffStatus ? "Active" : "Pending");

    if (file?.selectedFile !== null) {
      formData.append("file", file?.selectedFile);
    }

    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/staff`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPostHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/staff");
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Staff" breadcrumbItem="Add Staff" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addStaff-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      <h4>Basic Info</h4>
                      <Row>
                        <Col md="3">
                          <Controller
                            name="roleId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Role is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Role</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={roles}
                                />
                                {errors?.roleId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.roleId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="name">Name</Label>
                                <Input
                                  invalid={errors.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.name?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="name"
                            control={control}
                            rules={{ required: "Name is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="email">Email</Label>
                                <Input
                                  type="email"
                                  invalid={errors.email}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.email?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="email"
                            rules={{ required: "Email is required" }}
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="phoneNumber">Phone Number</Label>
                                <Input
                                  invalid={errors.phoneNumber}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.phoneNumber?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="phoneNumber"
                            control={control}
                            rules={{ required: "Phone Number is required" }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="password">Password</Label>
                                <Input
                                  type="password"
                                  invalid={errors.password}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.password?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="password"
                            rules={{ required: "Password is required" }}
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="dob">Date of Birth</Label>
                                <Input
                                  type="date"
                                  invalid={errors.dob}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.dob?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="dob"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="currentAddress">
                                  Current Address
                                </Label>
                                <Input
                                  invalid={errors.currentAddress}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.currentAddress?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="currentAddress"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="permanentAddress">
                                  Permanent Address
                                </Label>
                                <Input
                                  invalid={errors.permanentAddress}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.permanentAddress?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="permanentAddress"
                            control={control}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            name="branchId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Branch is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Branch</Label>
                                <SelectComponent
                                  field={field}
                                  options={branches}
                                />
                                {errors?.branchId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.branchId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md="3">
                          <Label for="icon">Profile Img</Label>
                          <div className="fileUploader">
                            <div className="fileContainer">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  document.getElementById("getFile").click();
                                  setFile({
                                    selectedFile: null,
                                    filePreview: null,
                                  });
                                }}
                              >
                                <i className="uil-cloud-upload mr5"></i>{" "}
                                <small>
                                  {file?.selectedFile
                                    ? file?.selectedFile?.name
                                    : "Upload file here"}
                                </small>
                              </div>
                            </div>
                          </div>

                          <input
                            type="file"
                            onChange={onFileChange}
                            id="getFile"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>

                      {/* <Row style={{ marginTop: 10 }}>
                        <Col md="3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="formrow-customCheck"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="formrow-customCheck"
                            >
                              Is Super Admin
                            </Label>
                          </div>
                        </Col>
                      </Row> */}

                      <h4 style={{ marginTop: 30 }}>Bank Info</h4>
                      <Row>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="bankName">Bank Name</Label>
                                <Input
                                  invalid={errors.bankName}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.bankName?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="bankName"
                            control={control}
                          />
                        </Col>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="bankBranchName">
                                  Bank Branch Name
                                </Label>
                                <Input
                                  invalid={errors.bankBranchName}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.bankBranchName?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="bankBranchName"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="accountName">Account Name</Label>
                                <Input
                                  invalid={errors.accountName}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.accountName?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="accountName"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="accountNumber">
                                  Account Number
                                </Label>
                                <Input
                                  invalid={errors.accountNumber}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.accountNumber?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="accountNumber"
                            control={control}
                          />
                        </Col>
                      </Row>

                      <h4 style={{ marginTop: 30 }}>Payroll Info</h4>
                      <Row>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="dateOfJoining">
                                  Date of Joining
                                </Label>
                                <Input
                                  type="date"
                                  invalid={errors.dateOfJoining}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.dateOfJoining?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="dateOfJoining"
                            control={control}
                          />
                        </Col>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="basicSalary">Basic Salary</Label>
                                <Input
                                  invalid={errors.basicSalary}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.basicSalary?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="basicSalary"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="employmentType"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="employmentType">
                                  Employment Type
                                </Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={[
                                    {
                                      label: "Provision",
                                      value: "Provision",
                                    },
                                    {
                                      label: "Contract",
                                      value: "Contract",
                                    },
                                    { label: "Permanent", value: "Permanent" },
                                  ]}
                                />
                                {errors?.employmentType?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.employmentType?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="provisionTime">
                                  Provision Time{" "}
                                  <small style={{ fontSize: 9 }}>
                                    (IN MONTHS)
                                  </small>
                                </Label>
                                <Input
                                  invalid={errors.provisionTime}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.provisionTime?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="provisionTime"
                            control={control}
                          />
                        </Col>
                      </Row>

                      <Row className="mt20">
                        <Col md="3">
                          <div className="form-check">
                            <Input
                              checked={staffStatus}
                              onChange={() => setStaffStatus(!staffStatus)}
                              type="checkbox"
                              className="form-check-input"
                              id="formrow-customCheck"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="formrow-customCheck"
                            >
                              Active
                            </Label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/staff")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddStaff;
