import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";
import AuthorizedPutHeaders from "../../configs/AuthorizedPutHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const EditClient = ({ history }) => {
  const params = useParams();

  const { id } = params;
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState("initial");
  const [singleData, setSingleData] = useState({});
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });
  const [cities, setCities] = useState([]);

  const [branches, setBranches] = useState([]);

  const [clientCategories, setClientCategories] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const fetchCities = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCities([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCities(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const fetchBranches = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/branch`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setBranches([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setBranches(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchClientCategories = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/clientCategory`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setClientCategories([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setClientCategories(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchClientCategories();
  }, []);

  // Fetching Single Details
  const fetchSingleDetail = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/client/${id}`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        setSingleData(response?.data);
        if (response?.data?.file) {
          setFile({
            selectedFile: response?.data?.file,
          });
        }

        // Selected
        let selectedCity = { value: null, label: "Select City" };

        if (response?.data?.cityId) {
          if (cities?.length > 0) {
            let filterRow = cities.filter(
              (row) => row.value === response?.data?.cityId
            );
            if (filterRow?.length > 0) {
              selectedCity.value = filterRow?.[0]?.value;
              selectedCity.label = filterRow?.[0]?.label;
            }
          }
        }

        // Selected Branch
        let selectedBranch = { value: null, label: "Select Branch" };

        if (response?.data?.branchId) {
          if (branches?.length > 0) {
            let filterBranchRow = branches.filter(
              (row) => row.value === response?.data?.branchId
            );
            if (filterBranchRow?.length > 0) {
              selectedBranch.value = filterBranchRow?.[0]?.value;
              selectedBranch.label = filterBranchRow?.[0]?.label;
            }
          }
        }

        // Selected Client Categories
        let selectedClientCategories = {
          value: null,
          label: "Select Client Category",
        };

        if (response?.data?.clientCategoryId) {
          if (clientCategories?.length > 0) {
            let filterClientCategoryRow = clientCategories.filter(
              (row) => row.value === response?.data?.clientCategoryId
            );
            if (filterClientCategoryRow?.length > 0) {
              selectedClientCategories.value =
                filterClientCategoryRow?.[0]?.value;
              selectedClientCategories.label =
                filterClientCategoryRow?.[0]?.label;
            }
          }
        }

        reset({
          name: response?.data?.name,
          email: response?.data?.email,
          phoneNumber: response?.data?.phoneNumber,
          cityId: selectedCity,
          branchId: selectedBranch,
          clientCategoryId: selectedClientCategories,
          gender: {
            label: response?.data?.gender,
            value: response?.data?.gender,
          },
          clientAddress: response?.data?.address,
        });
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      history.push("/client");
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchSingleDetail();
  }, [id, reset, cities, branches, clientCategories]);

  // File Method
  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  // Form Submit
  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("phoneNumber", data?.phoneNumber);
    formData.append("gender", data?.gender?.value);
    formData.append("address", data?.clientAddress);
    formData.append("clientCategoryId", data?.clientCategoryId?.value);
    formData.append("cityId", data?.cityId?.value);
    formData.append("branchId", data?.branchId?.value);
    if (file?.selectedFile && file?.filePreview) {
      formData.append("file", file?.selectedFile);
    }
    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/client/${id}`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPutHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/client");
        toast.success("Successfully updated");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Clients" breadcrumbItem="Edit Client" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addproduct-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      {Object.keys(singleData?.length === 0) &&
                        state === "loading" && (
                          <center>
                            <Spinner
                              style={{ width: "2rem", height: "2rem" }}
                              children={false}
                            />
                          </center>
                        )}
                      {Object.keys(singleData?.length > 0) &&
                        state === "success" && (
                          <>
                            <Row>
                              <Col md="3">
                                <Controller
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="name">Name</Label>
                                      <Input
                                        invalid={errors.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                      />
                                      <FormFeedback tooltip className="mb20">
                                        {errors?.name?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  )}
                                  name="name"
                                  control={control}
                                  rules={{
                                    required: "Name is required",
                                  }}
                                />
                              </Col>

                              <Col md="3">
                                <Controller
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="email">Email</Label>
                                      <Input
                                        type="email"
                                        invalid={errors.email}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                      />
                                      <FormFeedback tooltip className="mb20">
                                        {errors?.email?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  )}
                                  name="email"
                                  rules={{
                                    required: "Email is required",
                                  }}
                                  control={control}
                                />
                              </Col>

                              <Col md="3">
                                <Controller
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="phoneNumber">
                                        Phone Number
                                      </Label>
                                      <Input
                                        invalid={errors.phoneNumber}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                      />
                                      <FormFeedback tooltip className="mb20">
                                        {errors?.phoneNumber?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  )}
                                  name="phoneNumber"
                                  control={control}
                                  rules={{
                                    required: "Phone Number is required",
                                  }}
                                />
                              </Col>

                              <Col md="3">
                                <Controller
                                  name="gender"
                                  defaultValue=""
                                  control={control}
                                  rules={{
                                    required: "Gender required",
                                  }}
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="title">Select Gender</Label>
                                      <SelectComponent
                                        isMulti={false}
                                        field={field}
                                        options={[
                                          {
                                            label: "Male",
                                            value: "Male",
                                          },
                                          {
                                            label: "Female",
                                            value: "Female",
                                          },
                                        ]}
                                      />
                                      {errors?.gender?.message && (
                                        <FormFeedback className="invalid-tooltip-select mb20">
                                          {errors?.gender?.message}
                                        </FormFeedback>
                                      )}
                                    </FormGroup>
                                  )}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3">
                                <Controller
                                  name="clientCategoryId"
                                  defaultValue=""
                                  control={control}
                                  rules={{
                                    required: "Client Category required",
                                  }}
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="title">
                                        Select Client Category
                                      </Label>
                                      <SelectComponent
                                        isMulti={false}
                                        field={field}
                                        options={clientCategories}
                                      />
                                      {errors?.clientCategoryId?.message && (
                                        <FormFeedback className="invalid-tooltip-select mb20">
                                          {errors?.clientCategoryId?.message}
                                        </FormFeedback>
                                      )}
                                    </FormGroup>
                                  )}
                                />
                              </Col>

                              <Col md="3">
                                <Controller
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="clientAddress">
                                        Client Address
                                      </Label>
                                      <Input
                                        invalid={errors.clientAddress}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                      />
                                      <FormFeedback tooltip className="mb20">
                                        {errors?.clientAddress?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  )}
                                  name="clientAddress"
                                  control={control}
                                />
                              </Col>

                              <Col md="3">
                                <Controller
                                  name="cityId"
                                  defaultValue=""
                                  control={control}
                                  rules={{
                                    required: "City is required",
                                  }}
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="title">Select City</Label>
                                      <SelectComponent
                                        field={field}
                                        options={cities}
                                      />
                                      {errors?.cityId?.message && (
                                        <FormFeedback className="invalid-tooltip-select mb20">
                                          {errors?.cityId?.message}
                                        </FormFeedback>
                                      )}
                                    </FormGroup>
                                  )}
                                />
                              </Col>

                              <Col md="3">
                                <Controller
                                  name="branchId"
                                  defaultValue=""
                                  control={control}
                                  rules={{
                                    required: "Branch is required",
                                  }}
                                  render={({ field }) => (
                                    <FormGroup className="position-relative">
                                      <Label for="title">Select Branch</Label>
                                      <SelectComponent
                                        field={field}
                                        options={branches}
                                      />
                                      {errors?.branchId?.message && (
                                        <FormFeedback className="invalid-tooltip-select mb20">
                                          {errors?.branchId?.message}
                                        </FormFeedback>
                                      )}
                                    </FormGroup>
                                  )}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/client")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditClient;
