import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddExpense = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });

  const [cities, setCities] = useState([]);

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile({
        selectedFile: event.target.files[0],
        filePreview: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const fetchCities = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCities([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCities(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);

    // Form Data
    const formData = new FormData();
    formData.append("title", data?.title);

    formData.append("phoneNumber", data?.phoneNumber);

    formData.append("latitude", data?.latitude);

    formData.append("longitude", data?.longitude);

    formData.append("postcode", data?.postcode);

    formData.append("branchId", data?.branchId?.value);

    formData.append("regNo", data?.regNo);

    formData.append("ntnNo", data?.ntnNo);

    if (file?.selectedFile !== null) {
      formData.append("file", file?.selectedFile);
    }

    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/expense`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPostHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/expense");
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Expenses" breadcrumbItem="Add Expense" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addExpense-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Title</Label>
                                <Input
                                  invalid={errors.title}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.title?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="title"
                            control={control}
                            rules={{ required: "Title is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            defaultValue=""
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="date">Date</Label>
                                <Input
                                  type="date"
                                  defaultValue=""
                                  invalid={errors.date}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.date?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="date"
                            control={control}
                            rules={{ required: "Date is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="expenseTypeId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Expense Type is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Expense Type</Label>
                                <SelectComponent
                                  field={field}
                                  options={[
                                    {
                                      label: "Utility Bills",
                                      value: "Utility Bills",
                                    },
                                  ]}
                                />
                                {errors?.expenseTypeId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.expenseTypeId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="branchId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "City is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Branch</Label>
                                <SelectComponent
                                  field={field}
                                  options={cities}
                                />
                                {errors?.branchId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.branchId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <Label for="icon">Supported Document</Label>
                          <div className="fileUploader">
                            <div className="fileContainer">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  document.getElementById("getFile").click();
                                  setFile({
                                    selectedFile: null,
                                    filePreview: null,
                                  });
                                }}
                              >
                                <i className="uil-cloud-upload mr5"></i>{" "}
                                <small>
                                  {file?.selectedFile
                                    ? file?.selectedFile?.name
                                    : "Upload file here"}
                                </small>
                              </div>
                            </div>
                          </div>

                          <input
                            type="file"
                            onChange={onFileChange}
                            id="getFile"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/expense")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddExpense;
