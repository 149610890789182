export default function AuthorizedPutHeader(request, token, type = "JSON") {
  let options = { "x-access-token": token };
  if (type === "JSON") {
    options["Accept"] = "application/json";
    options["Content-Type"] = "application/json";
  } else {
    options["Accept"] = "*/*";
  }
  return {
    method: "PUT",
    headers: options,
    body: type === "JSON" ? JSON.stringify(request) : request,
  };
}
