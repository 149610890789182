import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiUrl } from "../../../configs/apiUrl";
import apiCall from "../../../configs/apiCall";
import PostHeaders from "../../../configs/PostHeaders";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postSocialLogin } from "../../../helpers/fakebackend_helper";

// Toaster
import toast from "react-hot-toast";

function* loginUser({ payload: { user, history } }) {
  try {
    const requestUrl = `${apiUrl}/login`;
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders({
        email: user.email,
        password: user.password,
      })
    );

    if (response.status === "success") {
      localStorage.setItem("authUser", JSON.stringify(response?.data?.user));
      localStorage.setItem("token", JSON.stringify(response?.data?.token));
      yield put(loginSuccess(response?.success));
      toast.success("Successfully Logged in.");
      history.push("/dashboard");
    } else {
      yield put(apiError(response?.message));
    }
  } catch (error) {
    yield put(apiError(error));

    toast.error(error?.message);
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // const token = JSON.parse(localStorage.getItem("token"));

    yield put(logoutUserSuccess(true));
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
