import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: "100%" }}
        ref={ref}
        className="sidebar-menu-scroll"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="uil-home-alt"></i>
                <span className="badge rounded-pill bg-primary float-end">
                  new
                </span>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-code-branch"></i>
                <span>Office</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/city">City</Link>
                </li>
                <li>
                  <Link to="/branch">Branches</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-user"></i>
                <span>Human Resource</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/role">Roles</Link>
                </li>
                <li>
                  <Link to="/staff">Staff</Link>
                </li>
                <li>
                  <Link to="/attendance">Attendance</Link>
                </li>
                {/* <li>
                  <Link to="/">Attendance Report</Link>
                </li> */}
                <li>
                  <Link to="/event">Event</Link>
                </li>
                {/* <li>
                  <Link to="/">Payroll</Link>
                </li>
                <li>
                  <Link to="/">Payroll Reports</Link>
                </li> */}
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-user-check"></i>
                <span>Client</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/client-category">Client Category</Link>
                </li>
                <li>
                  <Link to="/client">Clients</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-building"></i>
                <span>Court</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/court-category">Court Category</Link>
                </li>
                <li>
                  <Link to="/court">Courts</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-briefcase"></i>
                <span>Case</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/case-category">Case Category</Link>
                </li>
                <li>
                  <Link to="/case">Cases</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-transaction"></i>
                <span>Tasks</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/completed-tasks">Completed Tasks</Link>
                </li>
                <li>
                  <Link to="/pending-tasks">Pending Tasks</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/" className="waves-effect">
                <i className="uil-calendar-alt"></i>

                <span>Appointments</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-money-bill"></i>
                <span>Accounts</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/expense-type">Expense Types</Link>
                </li>
                <li>
                  <Link to="/expense">Expenses</Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-transaction"></i>
                <span>Leaves</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/">Leave Type</Link>
                </li>
                <li>
                  <Link to="/">Apply Leave</Link>
                </li>
                <li>
                  <Link to="/">Approved Leaves</Link>
                </li>
                <li>
                  <Link to="/">Pending Leaves</Link>
                </li>
                <li>
                  <Link to="/">Holiday Setup</Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/to-do" className="waves-effect">
                <i className="uil-calculator-alt"></i>

                <span>To Do</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/notices" className="waves-effect">
                <i className="uil-android-phone-slash"></i>

                <span>Notices</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/settings" className="waves-effect">
                <i className="uil-setting"></i>

                <span>System Settings</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
