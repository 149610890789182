import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";
import AuthorizedPutHeaders from "../../configs/AuthorizedPutHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const EditCity = ({ history }) => {
  const params = useParams();

  const { id } = params;
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState("initial");
  const [singleData, setSingleData] = useState({});
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });
  const [cities, setCities] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  // Fetching Single Details
  const fetchSingleDetail = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city/${id}`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        setSingleData(response?.data);
        if (response?.data?.file) {
          setFile({
            selectedFile: response?.data?.file,
          });
        }

        reset({
          title: response?.data?.title,
        });
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      history.push("/city");
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchSingleDetail();
  }, [id, reset]);

  // Form Submit
  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("title", data?.title);
    if (file?.selectedFile && file?.filePreview) {
      formData.append("file", file?.selectedFile);
    }
    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city/${id}`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPutHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/city");
        toast.success("Successfully updated");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Cities" breadcrumbItem="Edit City" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addproduct-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      {Object.keys(singleData?.length === 0) &&
                        state === "loading" && (
                          <center>
                            <Spinner
                              style={{ width: "2rem", height: "2rem" }}
                              children={false}
                            />
                          </center>
                        )}
                      {Object.keys(singleData?.length > 0) &&
                        state === "success" && (
                          <>
                            <Row>
                              <Col lg="12">
                                <div
                                  id="addRole-accordion"
                                  className="custom-accordion"
                                >
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col md="3">
                                          <Controller
                                            render={({ field }) => (
                                              <FormGroup className="position-relative">
                                                <Label for="title">Title</Label>
                                                <Input
                                                  invalid={errors.title}
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  inputRef={field.ref}
                                                />
                                                <FormFeedback
                                                  tooltip
                                                  className="mb20"
                                                >
                                                  {errors?.title?.message}
                                                </FormFeedback>
                                              </FormGroup>
                                            )}
                                            name="title"
                                            control={control}
                                            rules={{
                                              required: "Title is required",
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/city")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCity;
