import React from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

const LatestTransaction = () => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-4">04-June-2022 Case list</CardTitle>
            <div className="table-responsive">
              <Table className="table-centered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th>ID</th>
                    <th>Case No</th>
                    <th>Date</th>
                    <th>Associates</th>
                    <th>Client</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        1
                      </Link>{" "}
                    </td>
                    <td>#MB2540</td>
                    <td>04 June, 2022</td>

                    <td>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Bassam
                      </span>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Sherry
                      </span>
                    </td>
                    <td>Awais Rafiq</td>

                    <td>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded waves-effect waves-light"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        1
                      </Link>{" "}
                    </td>
                    <td>#MB2540</td>
                    <td>04 June, 2022</td>

                    <td>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Bassam
                      </span>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Sherry
                      </span>
                    </td>
                    <td>Awais Rafiq</td>

                    <td>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded waves-effect waves-light"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        1
                      </Link>{" "}
                    </td>
                    <td>#MB2540</td>
                    <td>04 June, 2022</td>

                    <td>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Bassam
                      </span>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Sherry
                      </span>
                    </td>
                    <td>Awais Rafiq</td>

                    <td>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded waves-effect waves-light"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        1
                      </Link>{" "}
                    </td>
                    <td>#MB2540</td>
                    <td>04 June, 2022</td>

                    <td>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Bassam
                      </span>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Sherry
                      </span>
                    </td>
                    <td>Awais Rafiq</td>

                    <td>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded waves-effect waves-light"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        1
                      </Link>{" "}
                    </td>
                    <td>#MB2540</td>
                    <td>04 June, 2022</td>

                    <td>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Bassam
                      </span>
                      <span className="badge rounded-pill bg-soft-success font-size-12">
                        Sherry
                      </span>
                    </td>
                    <td>Awais Rafiq</td>

                    <td>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded waves-effect waves-light"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default LatestTransaction;
