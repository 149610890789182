export default function AuthorizedDeleteHeader(token) {
  return {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-access-token': token,
    },
  };
}
