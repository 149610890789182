import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const CaseDetails = () => {
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Case" breadcrumbItem="Case Details" />

          <Row>
            <Col lg="8">
              <div id="CaseDetails-accordion" className="custom-accordion">
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <div>
                        <button className="btn btn-primary btn-sm mr-5">
                          <i className="mdi mdi-plus me-1"></i> New Progress
                        </button>

                        <button className="btn btn-info btn-sm mr-5">
                          Edit Case
                        </button>

                        <button className="btn btn-danger btn-sm mr-5">
                          Delete Case
                        </button>

                        <button className="btn btn-warning btn-sm">
                          Dispose Case
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <div className="border p-4 rounded">
                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>28th May, 2022</strong>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Add File
                              </button>

                              <button className="btn btn-secondary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Case Type: </strong>
                            Go To Trial
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Description: </strong>
                            Dummy Description
                          </p>
                        </div>

                        {/* For Files */}
                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              1. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              2. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              3. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              4. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="border p-4 rounded">
                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>28th May, 2022</strong>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Add File
                              </button>

                              <button className="btn btn-secondary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Case Type: </strong>
                            Go To Trial
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Description: </strong>
                            Dummy Description
                          </p>
                        </div>

                        {/* For Files */}
                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              1. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              2. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              3. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <Link to="#">
                              4. 2b62833694cc1e51452683c14f685037.jpg
                            </Link>
                            <div>
                              <button className="btn btn-primary btn-sm mr-5">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm">
                                Delete
                              </button>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col lg="4">
              <div id="CaseDetails-accordion" className="custom-accordion">
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <div className="border p-4 rounded">
                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Details </strong>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Case No: </strong>
                            ABC vs BVC 455
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>File No: </strong>
                            333
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Filing Date: </strong>
                            2nd Nov, 2021
                          </p>
                        </div>

                        <div className="">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Next Hearing Date: </strong>
                            28th May, 2022
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="border p-4 rounded">
                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Client </strong>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Name: </strong>
                            ABC
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Mobile: </strong>
                            4433222222
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Email: </strong>
                            abc@gmail.com
                          </p>
                        </div>

                        <div className="">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Location: </strong>
                            Peshawar
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="border p-4 rounded">
                        <div className="border-bottom">
                          <p className="text-muted text-muted-p">
                            <strong>Court Details </strong>
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Name: </strong>
                            High Court Peshawar
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Category: </strong>
                            Civil Courts
                          </p>
                        </div>

                        <div className="border-bottom">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Room No: </strong>
                            503
                          </p>
                        </div>

                        <div className="">
                          <p className="text-muted aligned-p text-muted-p">
                            <strong>Address: </strong>
                            Peshawar
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseDetails;
