import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Alert,
  Container,
  CardBody,
  Card,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

const Login = (props) => {
  const [loader, setLoader] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // handleValidSubmit
  const onSubmit = (data) => {
    setLoader(true);
    props.loginUser(data, props.history);
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  useEffect(() => {
    if (props.error && typeof props.error === "string") {
      setLoader(false);
    }
  }, [props.error]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    width="20%"
                    className="logo logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    width="20%"
                    className="logo logo-light"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to Admin Portal.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Controller
                          render={({ field }) => (
                            <FormGroup className="position-relative">
                              <Label for="email">Email</Label>
                              <Input
                                invalid={errors.email}
                                value={field.value}
                                onChange={field.onChange}
                                inputRef={field.ref}
                              />
                              <FormFeedback tooltip className="mb20">
                                {errors?.email?.message}
                              </FormFeedback>
                            </FormGroup>
                          )}
                          name="email"
                          control={control}
                          rules={{ required: "Email is required" }}
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                        <Controller
                          render={({ field }) => (
                            <FormGroup className="position-relative">
                              <Label for="email">Password</Label>
                              <Input
                                type="password"
                                invalid={errors.password}
                                value={field.value}
                                onChange={field.onChange}
                                inputRef={field.ref}
                              />
                              <FormFeedback tooltip className="mb20">
                                {errors?.password?.message}
                              </FormFeedback>
                            </FormGroup>
                          )}
                          name="password"
                          control={control}
                          rules={{ required: "Password is required" }}
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          disabled={loader}
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} MLF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Team The
                  Binary Ops
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
