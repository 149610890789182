import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Button, Spinner, Table } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Placeholder
import placeHolder from "../../assets/images/placeholder.jpg";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";
import AuthorizedDeleteHeader from "../../configs/AuthorizedDeleteHeader";

// Toaster
import toast from "react-hot-toast";
import { LIMIT } from "../../helpers/pagination";

const ExpenseTypeList = ({ history }) => {
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState("success"); //after calling the API make it to initial

  // Fetching categories
  const dummyData = [
    {
      id: 1,
      title: "Daily Expense",
    },
    {
      id: 2,
      title: "Salary",
    },
    {
      id: 3,
      title: "Utility Bills",
    },
    {
      id: 3,
      title: "Miscellaneous",
    },
  ];
  const [categories, setCategories] = useState(dummyData);

  // Pagination
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const fetchCategories = async () => {
    setLoader(true);
    if (page === 1) {
      setState("loading");
    }
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/expenseType?limit=${LIMIT}&page=${page}`;

    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token), history);

      if (response?.data?.rows?.length > 0) {
        setLoader(false);
        setState("success");
        setCategories((categories) => [...categories, ...response?.data?.rows]);
        if (response?.data?.hasOwnProperty("next")) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else {
        setLoader(false);
        setState("success");
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, [page]);

  const editRecordHandler = (id) => {
    history.push(`/edit-expense-type/${id}`);
  };

  const deleteRecordHandler = async (id) => {
    if (window.confirm("You want to delete this entry?")) {
      const token = JSON.parse(localStorage.getItem("token"));
      let url = `${apiUrl}/expenseType/${id}`;
      try {
        let response = await apiCall(url, AuthorizedDeleteHeader(token));
        if (response) {
          setCategories([]);
          fetchCategories();
          toast.success("Successfully Deleted");
        }
      } catch (err) {
        setLoader(false);
        toast.error(err);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Expense Types"
            breadcrumbItem="Expense Type List"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <Button
                      onClick={() => history.push("/add-expense-type")}
                      type="button"
                      color="success"
                      className="waves-effect waves-light mb-3"
                    >
                      <i className="mdi mdi-plus me-1"></i>
                      Add New Expense Type
                    </Button>
                  </div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sno</th>
                            <th className="text-center" data-priority="1">
                              Title
                            </th>

                            {/* {userDetails?.roleId === 1 && ( */}
                            <th className="text-center" data-priority="3">
                              Action
                            </th>
                            {/* )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {categories?.length === 0 && state === "loading" && (
                            <tr>
                              <td colSpan="6">
                                <center>
                                  <Spinner
                                    style={{ width: "2rem", height: "2rem" }}
                                    children={false}
                                  />
                                </center>
                              </td>
                            </tr>
                          )}

                          {categories?.length === 0 && state === "success" && (
                            <tr>
                              <td colSpan="6">
                                <center>NO RECORD FOUND....</center>
                              </td>
                            </tr>
                          )}

                          {categories?.length === 0 && state === "error" && (
                            <tr>
                              <td colSpan="6">
                                <center>
                                  Something went wrong please try again....
                                </center>
                              </td>
                            </tr>
                          )}

                          {categories?.length > 0 &&
                            state === "success" &&
                            categories.map((item, index) => (
                              <tr key={`cr-${item.id}`}>
                                <th>{index + 1}</th>

                                <td>{item.title}</td>

                                {/* {userDetails?.roleId === 1 && ( */}
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() => editRecordHandler(item.id)}
                                    className="text-primary"
                                  >
                                    <i className="uil uil-pen font-size-18"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() => deleteRecordHandler(item.id)}
                                    className="text-danger"
                                  >
                                    <i className="uil uil-trash-alt font-size-18"></i>
                                  </Link>
                                </td>

                                {/* )} */}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    {hasMore && (
                      <Col className="col-12">
                        <center>
                          <Button
                            onClick={() => {
                              setPage(page + 1);
                            }}
                            type="button"
                            color="primary"
                            className="waves-effect waves-light mb-3"
                          >
                            {/* <i className="mdi mdi-loading-full me-1"></i> */}
                            {loader ? "loading..." : "Load More"}
                          </Button>
                        </center>
                      </Col>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpenseTypeList;
