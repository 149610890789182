import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// API
import apiCall from "../../configs/apiCall";
import { apiUrl } from "../../configs/apiUrl";
import AuthorizedPostHeaders from "../../configs/AuthorizedPostHeaders";
import AuthorizedGetHeaders from "../../configs/AuthorizedGetHeaders";

// Toaster
import toast from "react-hot-toast";
import SelectComponent from "../../components/Common/SelectComponent";

const AddCourt = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [, setState] = useState("initial");
  const [file, setFile] = useState({
    selectedFile: null,
    filePreview: null,
  });

  const [courtCategories, setCourtCategories] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCourtCategories = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/courtCategory`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCourtCategories([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCourtCategories(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCourtCategories();
  }, []);

  const fetchCities = async () => {
    setLoader(true);
    setState("loading");
    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/city`;
    try {
      let response = await apiCall(url, AuthorizedGetHeaders(token));
      if (response) {
        setLoader(false);
        setState("success");
        let dataArray = [];
        if (response?.data?.rows?.length > 0) {
          setCities([]);
          response?.data?.rows.map((item) =>
            dataArray.push({ value: item?.id, label: item?.title })
          );
          setCities(dataArray);
        }
      }
    } catch (err) {
      setLoader(false);
      setState("error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);
    // Form Data
    const formData = new FormData();
    formData.append("courtName", data?.courtName);
    formData.append("courtRoomNo", data?.courtRoomNo);
    formData.append("location", data?.location);
    formData.append("cityId", data?.cityId?.value);
    formData.append("courtCategoryId", data?.courtCategoryId?.value);
    if (file?.selectedFile !== null) {
      formData.append("file", file?.selectedFile);
    }

    // Form Data End

    const token = JSON.parse(localStorage.getItem("token"));
    let url = `${apiUrl}/court`;
    try {
      let response = await apiCall(
        url,
        AuthorizedPostHeaders(formData, token, "formData")
      );
      if (response.status === "success") {
        setLoader(false);
        history.push("/court");
        toast.success("Successfully added");
      }
      if (response.status === "error") {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Court" breadcrumbItem="Add Court" />

          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12">
                <div id="addCourt-accordion" className="custom-accordion">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="name">Court Name</Label>
                                <Input
                                  invalid={errors.courtName}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.courtName?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="courtName"
                            control={control}
                            rules={{ required: "Court Name is required" }}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="roomNo">Court Room No</Label>
                                <Input
                                  type="text"
                                  invalid={errors.courtRoomNo}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.courtRoomNo?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="courtRoomNo"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="location">Location</Label>
                                <Input
                                  invalid={errors.location}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.location?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="location"
                            control={control}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            name="cityId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "City is required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select City</Label>
                                <SelectComponent
                                  field={field}
                                  options={cities}
                                />
                                {errors?.cityId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.cityId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Controller
                            name="courtCategoryId"
                            defaultValue=""
                            control={control}
                            rules={{ required: "Court Category required" }}
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="title">Select Court Category</Label>
                                <SelectComponent
                                  isMulti={false}
                                  field={field}
                                  options={courtCategories}
                                />
                                {errors?.courtCategoryId?.message && (
                                  <FormFeedback className="invalid-tooltip-select mb20">
                                    {errors?.courtCategoryId?.message}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            )}
                          />
                        </Col>

                        <Col md="3">
                          <Controller
                            render={({ field }) => (
                              <FormGroup className="position-relative">
                                <Label for="courtAddress">Court Address</Label>
                                <Input
                                  invalid={errors.courtAddress}
                                  value={field.value}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                />
                                <FormFeedback tooltip className="mb20">
                                  {errors?.courtAddress?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                            name="courtAddress"
                            control={control}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="row mb-4">
              <div className="col text-end">
                <Button
                  onClick={() => history.push("/court")}
                  type="button"
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="uil uil-times me-1"></i> Cancel{" "}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loader}
                  className="btn btn-success"
                >
                  {" "}
                  <i className="uil uil-file-alt me-1"></i> Save{" "}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCourt;
