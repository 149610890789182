import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Brand
import Branch from "../pages/Branch";
import AddBranch from "../pages/Branch/AddBranch";
import EditBranch from "../pages/Branch/EditBranch";

// Brand
import City from "../pages/City";
import AddCity from "../pages/City/AddCity";
import EditCity from "../pages/City/EditCity";

// Role & Permission
import Role from "../pages/Role";
import AddRole from "../pages/Role/AddRole";
import EditRole from "../pages/Role/EditRole";
import AssignPermission from "../pages/Permission/AssignPermission";

// Staff
import Staff from "../pages/Staff";
import AddStaff from "../pages/Staff/AddStaff";
import EditStaff from "../pages/Staff/EditStaff";

// Attendance

import Attendance from "../pages/Attendance";

// Client Category
import ClientCategory from "../pages/ClientCategory";
import AddClientCategory from "../pages/ClientCategory/AddClientCategory";
import EditClientCategory from "../pages/ClientCategory/EditClientCategory";

// Client
import Client from "../pages/Client";
import AddClient from "../pages/Client/AddClient";
import EditClient from "../pages/Client/EditClient";

// Court Category
import CourtCategory from "../pages/CourtCategory";
import AddCourtCategory from "../pages/CourtCategory/AddCourtCategory";
import EditCourtCategory from "../pages/CourtCategory/EditCourtCategory";

// Court
import Court from "../pages/Court";
import AddCourt from "../pages/Court/AddCourt";
import EditCourt from "../pages/Court/EditCourt";

// Case Category
import CaseCategory from "../pages/CaseCategory";
import AddCaseCategory from "../pages/CaseCategory/AddCaseCategory";
import EditCaseCategory from "../pages/CaseCategory/EditCaseCategory";

// Case
import Case from "../pages/Case";
import AddCase from "../pages/Case/AddCase";
import EditCase from "../pages/Case/EditCase";
import CaseDetails from "../pages/Case/CaseDetails";

// Tasks
import Task from "../pages/Task";
import AddTask from "../pages/Task/AddTask";
import EditTask from "../pages/Task/EditTask";
import TaskDetails from "../pages/Task/TaskDetails";

// Event
import Event from "../pages/Event";
import AddEvent from "../pages/Event/AddEvent";
import EditEvent from "../pages/Event/EditEvent";

// To Do
import ToDo from "../pages/ToDo";
import AddToDo from "../pages/ToDo/AddToDo";
import EditToDo from "../pages/ToDo/EditToDo";

// Expense Type
import ExpenseType from "../pages/ExpenseType";
import AddExpenseType from "../pages/ExpenseType/AddExpenseType";
import EditExpenseType from "../pages/ExpenseType/EditExpenseType";

// Expense
import Expense from "../pages/Expense";
import AddExpense from "../pages/Expense/AddExpense";
import EditExpense from "../pages/Expense/EditExpense";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Brand
  { path: "/branch", component: Branch },
  { path: "/add-branch", component: AddBranch },
  { path: "/edit-branch/:id", component: EditBranch },

  // Brand
  { path: "/city", component: City },
  { path: "/add-city", component: AddCity },
  { path: "/edit-city/:id", component: EditCity },

  // Role
  { path: "/role", component: Role },
  { path: "/add-role", component: AddRole },
  { path: "/edit-role/:id", component: EditRole },
  { path: "/roles/assign-permissions/:id", component: AssignPermission },

  // Staff
  { path: "/staff", component: Staff },
  { path: "/add-staff", component: AddStaff },
  { path: "/edit-staff/:id", component: EditStaff },

  // Attendance
  { path: "/attendance", component: Attendance },

  // Client Category
  { path: "/client-category", component: ClientCategory },
  { path: "/add-client-category", component: AddClientCategory },
  { path: "/edit-client-category/:id", component: EditClientCategory },

  // Client
  { path: "/client", component: Client },
  { path: "/add-client", component: AddClient },
  { path: "/edit-client/:id", component: EditClient },

  // Court Category
  { path: "/court-category", component: CourtCategory },
  { path: "/add-court-category", component: AddCourtCategory },
  { path: "/edit-court-category/:id", component: EditCourtCategory },

  // Court
  { path: "/court", component: Court },
  { path: "/add-court", component: AddCourt },
  { path: "/edit-court/:id", component: EditCourt },

  // event
  { path: "/event", component: Event },
  { path: "/add-event", component: AddEvent },
  { path: "/edit-event/:id", component: EditEvent },

  // Casee Category
  { path: "/case-category", component: CaseCategory },
  { path: "/add-case-category", component: AddCaseCategory },
  { path: "/edit-case-category/:id", component: EditCaseCategory },

  // To Do
  { path: "/to-do", component: ToDo },
  { path: "/add-to-do", component: AddToDo },
  { path: "/edit-to-do/:id", component: EditToDo },

  // Expense Types
  { path: "/expense-type", component: ExpenseType },
  { path: "/add-expense-type", component: AddExpenseType },
  { path: "/edit-expense-type/:id", component: EditExpenseType },

  // Expense
  { path: "/expense", component: Expense },
  { path: "/add-expense", component: AddExpense },
  { path: "/edit-expense/:id", component: EditExpense },

  // Cases
  { path: "/case", component: Case },
  { path: "/add-case", component: AddCase },
  { path: "/edit-case/:id", component: EditCase },
  { path: "/case-details/:id", component: CaseDetails },

  // Task
  { path: "/completed-tasks", component: Task },
  { path: "/pending-tasks", component: Task },
  { path: "/add-task", component: AddTask },
  { path: "/edit-task/:id", component: EditTask },
  { path: "/task-details/:id", component: TaskDetails },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { userRoutes, authRoutes };
